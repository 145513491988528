import languages from 'xyz/src/app/locale/languages'

import fr from './fr'

const locale =
{
    // ----------

    languages,

    // ----------

    fr
};

export default locale ;
