import deepMerge from 'vegas-js-core/src/deepMerge'

import defaultFR from 'xyz/src/app/locale/fr'

import views from './views'

const fr = deepMerge( defaultFR ,
{
    views
});

export default fr ;
