import React from 'react'

import ReactDOM from 'react-dom'

import { create } from 'jss'

// --------------- POLYFILL

import 'core-js'
import 'regenerator-runtime'

// --------------- LIBRARIES

import jssExtend   from 'jss-extend'

import { StylesProvider , createGenerateClassName , jssPreset } from '@material-ui/styles'

// --------------- FONTS

import 'typeface-raleway' // Raleway
import 'typeface-roboto' // Roboto

import '@fortawesome/fontawesome-free/css/fontawesome.css'
import '@fortawesome/fontawesome-free/css/all.css'

// --------------- CSS

import 'xyz/src/app/styles/MaterialIcons.css'

import 'xyz/src/app/styles/normalize.css'
import 'xyz/src/app/styles/print.css'
import 'xyz/src/app/styles/prism.css'
import 'xyz/src/app/styles/react-table.css'
import 'xyz/src/app/styles/tables.css'
import 'xyz/src/app/styles/app.css'

// --------------- IMPORTS

import registerServiceWorker from 'xyz/src/app/workers/registerServiceWorker'

import Application from 'xyz/src/app/Application'

import { getConfig } from 'xyz/src/app/configs/config'

import initialize from 'xyz/src/app/initialize'

import './app/styles/helpers.css'

import assets  from './app/assets'
import locale  from './app/locale'
import style   from './app/styles/index.css'
import version from './app/version'

// ---------------

initialize( version ) ;

// ---------------

const config = getConfig( { assets , version } ) ;

// ---------------

const jss = create({
    ...jssPreset(),
    plugins : [ ...jssPreset().plugins, jssExtend() ]
});

jss.options.insertionPoint = document.getElementById('jss-insertion-point') ;

const generateClassName = createGenerateClassName();

const main =
    <StylesProvider jss={jss} generateClassName={generateClassName}>
        <Application
            config = { config }
            locale = { locale }
            style  = { style  }
        />
    </StylesProvider>;

ReactDOM.render( main , document.getElementById('root'));

registerServiceWorker();
